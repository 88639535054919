import styled from 'styled-components';

import logoIcon from '../../Images/svg/ASO-part-1.svg';
import logoText from '../../Images/svg/ASO-part-2.svg';

import {IThemeProvider} from '../../Theme/defaultTheme';
import {BasicContentSpan} from '../../Theme/basicElements';

interface IHeader {
  readonly 'data-rolled': boolean;
}

export const Header = styled.header<IHeader, IThemeProvider>`
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;
  height: ${props => props.theme.format.header_height};
  display: grid;
  grid-template-columns: 96px 1fr;
  transition: ease-in 500ms;
  background-color: ${props => props.theme.colors.white};

  &[data-rolled='true'] {
    grid-template-columns: 220px 1fr;
  }
`;

export const LogoBlock = styled.div<IHeader>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;

  &[data-rolled='true'] {
    flex-direction: row;
  }
`;

export const LogoIcon = styled.div`
  width: 57px;
  height: 22px;
  background-image: url(${logoIcon});
  background-repeat: no-repeat;
  background-size: contain;
`;

export const LogoTitle = styled.div`
  background-image: url(${logoText});
  background-repeat: no-repeat;
  background-size: contain;
  width: 62px;
  height: 18px;
`;

export const HeaderControls = styled.div<IThemeProvider>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.theme.colors.white};
  border-left: 1px solid ${props => props.theme.colors.grey_lightest};
  border-bottom: 1px solid ${props => props.theme.colors.grey_lightest};
  box-sizing: border-box;
  padding: ${props => props.theme.format.main_lg_padding};
`;

export const HeaderRightBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

export const HeaderNoVerifyWarning = styled(BasicContentSpan)<IThemeProvider>`
  color: ${props => props.theme.colors.secondary_red};
`;