import React from 'react';
import ContentProvider from '../ContentProvider';
import {useAppSelector} from '../../Hooks/redux/useAppSelector';
import {useLocation} from 'react-router-dom';
import PageHeader from '../PageHeader';
import {AppContent, Application} from './styled';
import PageAside from '../PageAside';
import {useActions} from '../../Hooks/redux/useActions';

import 'rsuite/dist/rsuite.min.css';
import '../../Styles/normalize.css';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CaptchaContainer from '../CaptchaContainer';
import {isMobile} from 'react-device-detect';
import MobilePage from '../../Pages/MobilePage';

import './variables.css';

declare global {
  interface Window {
    grecaptcha: {
      ready(callback: () => void): void;
      execute(sitekey: string, options: {
        action: string
      }): Promise<string>;
      // Добавьте другие методы, если необходимо
    };
  }
}

function App() {
  const location = useLocation();
  const {toggleAside} = useActions();

  const openMenu = useAppSelector(state => state.asideSlice.isOpen);

  // const {errors} = useAppSelector(state => state.errorsSlice);

  // if (errors.length > 0) {
  //   console.log(errors);
  // }

  const handleHideMenu = () => {
    toggleAside(!openMenu);
  };

  if (isMobile) {
    return (
      <MobilePage></MobilePage>
    );
  }

  return (
    <Application>
      {(location.pathname === '/authorization' || location.pathname.includes('/registration') || location.pathname.includes('/reset-password')) ?
        <CaptchaContainer/> :
        <>
          <PageHeader rolled={openMenu}/>
          <AppContent data-rolled={openMenu}>
            <PageAside handleHideMenu={handleHideMenu} rolled={openMenu}/>
            <ContentProvider/>
          </AppContent>
        </>
      }
      <ToastContainer autoClose={1000} pauseOnFocusLoss={false}/>
    </Application>
  );
}

export default App;
