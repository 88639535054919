import React, {useState} from 'react';
import styled from './styled.module.scss';

interface IInputProps {
  getInputValue: (value: string | null) => void;
  placeholder: string;
  size: 'md' | 'lg';
}

const SearchInput = ({getInputValue, placeholder, size}: IInputProps) => {
  const [value, setValue] = useState('');

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    if (e.target.value === '') {
      getInputValue(null);
    } else {
      getInputValue(e.target.value);
    }
  };

  return (
      <label data-size={size} className={styled.input_block}>
        <input className={styled.input} type="text" value={value} onChange={handleChangeValue} placeholder={placeholder} maxLength={30}/>
      </label>
  );
};

export default SearchInput;