import React from 'react';
import HelpButton from '../../Pages/CreateCampaignPage/Components/HelpButton';
import styled from './styled.module.scss';

interface IBasicRadioRowProps {
  name: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  text: string;
  checked: boolean;
  prompt?: string;
}

const BasicRadioRow: React.FC<IBasicRadioRowProps> = ({name, value, onChange, text, checked, prompt}) => {
  return (
    <div className={styled.radio_button_block}>
      <input className={styled.radio_button_input} type={'radio'} name={name} value={value} onChange={onChange} id={value} checked={checked}/>
      <label className={styled.radio_button_label} htmlFor={value}>{text}</label>
      {prompt && <HelpButton popupText={prompt}/>}
    </div>
  );
};

export default BasicRadioRow;