import React from 'react';
import styled from './styled.module.scss';

interface IMyButton {
  onClick: (e: React.MouseEvent<HTMLButtonElement> | any) => void,
  size: 'sm' | 'md' | 'lg',
  text: string,
  disabled?: boolean,
  variant: 'blue' | 'white' | 'red';
  title?: string;
  type?: 'button' | 'submit' | 'reset'
}

const BasicButton = ({onClick, text, size, disabled, variant, title, type}: IMyButton) => {
  return (
    <button
        className={styled.button}
      type={type}
      onClick={onClick}
      data-size={size}
      disabled={disabled}
      data-variant={variant}
      title={title}
    >
      {text}
    </button>
  );
};

export default BasicButton;