import React, {forwardRef, Ref} from 'react';

import styled from './styled.module.scss';

interface IBasicInput {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  value: string | number;
  size: 'lg';
  label: string;
  placeholder?: string;
  inError?: boolean;
  inErrorText?: string;
  type?: string;
}

const BasicInput = forwardRef(({
                                 onChange,
                                 value,
                                 size,
                                 label,
                                 placeholder,
                                 inError,
                                 type = 'text',
                                 inErrorText,
                                 onBlur,
                                 onFocus
                               }: IBasicInput, ref: Ref<HTMLLabelElement>) => (
  <label className={styled.label} ref={ref} data-size={size}>
    {label}
    <input
        className={styled.input}
      type={type}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      data-in-error={inError}
      onBlur={onBlur}
      onFocus={onFocus}
    />
    <span className={styled.error_text} data-in-error={inError}>{inErrorText}</span>
  </label>
));


export default BasicInput;