import React, {useState} from 'react';
import {BasicModalTitle, CloseButtonBlock, ModalInputBlock} from '../../../../../../Theme/modalsElements';
import BasicInput from '../../../../../../UI/BasicInput';
import BasicButton from '../../../../../../UI/BasicButton';
import RemoveCrossButton from '../../../../../../UI/RemoveCrossButton';
import {getStoreLinkId, throwNewToastError, throwNewToastSuccess} from '../../../../../../Utils/MainUtils';
import {useAddNewAppMutation} from '../../../../../../Store/APIs/Apps.api';
import {CountryDescription, CountryError, ModalError, NewAppModalContainer} from './styled';
import CustomLoader from '../../../../../../Components/Loaders/CustomLoader';
import {CountrySelector} from '../../../../../../Components/Selectors';
import modalStyles from '../../../../../../Theme/modalsElements.module.scss';
import styles from './styles.module.scss';


interface IAddNewAppModal {
  onClose: () => void;
  incomeAppLink?: string | null;
}

type selectedCountryType = string | null;
type errorsType = string[]

const AddNewAppModal = React.forwardRef(({
                                           onClose,
                                           incomeAppLink
                                         }: IAddNewAppModal, ref: React.ForwardedRef<HTMLDivElement>) => {
  const [appLink, setAppLink] = useState(incomeAppLink ?? '');
  const [selectedCountry, setSelectedCountry] = useState<selectedCountryType>(null);
  const [errorFields, setErrorsFields] = useState<errorsType>([]);

  const [apiErrorText, setApiErrorText] = useState('');

  const [addNewApp, {isLoading: isCreating}] = useAddNewAppMutation();

  const handleSetAppLink = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorsFields([]);
    setAppLink(e.target.value);
  };

  const handleChooseCountry = (ISO: string | null) => {
    setSelectedCountry(ISO);
    setErrorsFields([]);
  };

  const validateData = (link: string, country: string | null) => {
    let errors = [];
    const appId = getStoreLinkId(link);

    if (!country) {
      errors.push('empty country');
    }

    if (!appId) {
      errors.push('wrong link');
    }

    if (!errors.length) {
      return true;
    } else {
      setErrorsFields(errors);
      return false;
    }
  };

  const handleSubmit = () => {
    if (validateData(appLink, selectedCountry)) {
      const appId = getStoreLinkId(appLink);

      if (appId) {
        addNewApp({
          appid: appId,
          country: selectedCountry
        }).unwrap()
          .then((result) => {
            if ('id' in result) {
              onClose();
              throwNewToastSuccess('New app has been added');
            }
          })
          .catch((error) => {
            if ('status' in error && error.status === 403) {
              throwNewToastError('You need to confirm the email address');
              return;
            }

            if ('data' in error && error.data && 'error' in error.data && error.data.error) {
              if (error.data.error === 'The app is not available in the selected country') {
                setErrorsFields(['incorrect country']);
                setApiErrorText('The app is not available in the selected country');
              }
              if (error.data.error === 'This app has already been added') {
                setErrorsFields(['application exists']);
                setApiErrorText('This app has already been added');
              } else {
                throwNewToastError(error.data.error);
              }
            }
          });
      }
    }
  };

  return (
    <>
      <div ref={ref} tabIndex={-1} className={modalStyles.modal_wrapper + ' ' + styles.wrapper}>
        <CloseButtonBlock>
          <RemoveCrossButton onClick={onClose}/>
        </CloseButtonBlock>
        <BasicModalTitle>
          Add App
        </BasicModalTitle>
        <ModalInputBlock>
          <BasicInput
            inError={errorFields.includes('wrong link') || errorFields.includes('incorrect country') || errorFields.includes('application exists')}
            inErrorText={errorFields.includes('wrong link') ? 'Provide the link to Google Play or App Store' : ''}
            onChange={handleSetAppLink}
            value={appLink}
            size={'lg'}
            label={'Link from Google Play or App Store*'}
            placeholder={'App link'}/>
          <CountrySelector
            size={'lg'}
            placeholder={'Choose country'}
            selectedCountry={selectedCountry}
            handleSelectCountry={handleChooseCountry}
            inError={errorFields.includes('empty country')}
          />
        </ModalInputBlock>
        {errorFields.includes('empty country') ?
          <CountryError>
            Select a country
          </CountryError>
          : <CountryDescription>
            This field is required to get information about the application. At the same time, it is possible to promote
            the
            application in all countries where it is available for download.
          </CountryDescription>
        }
        <BasicButton
          disabled={isCreating || !(!!appLink.length && selectedCountry)}
          onClick={handleSubmit}
          size={'lg'}
          text={'Apply'}
          variant={'blue'}
        />
        {(errorFields.includes('incorrect country') || errorFields.includes('application exists')) &&
          <ModalError>{apiErrorText}</ModalError>}
      </div>
      {isCreating && <CustomLoader backdrop={true} size="lg" loadingText="Adding..."/>}
    </>
  );
});

export default AddNewAppModal;