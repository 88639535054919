import React, {forwardRef, ReactNode, Ref, RefObject} from 'react';
import styled from './styled.module.scss';

interface IComponentProps {
  children :ReactNode[] | ReactNode;
  ref :RefObject<HTMLDivElement>;
}

const CustomPopup = forwardRef((props :IComponentProps, ref :Ref<HTMLDivElement>) => (
  <div ref={ref} className={styled.popup}>
    {props.children}
  </div>
));


export default CustomPopup;