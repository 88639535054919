import React from 'react';
import HiddenEyeButton from '../HiddenEyeButton';
import styled from './styled.module.scss';

interface IAuthInputProps {
  fieldType: 'email' | 'password';
  fieldError: boolean;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  type: string;
  errorText?: string;
  isVisible?: boolean;
  handleSetVisible?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onBlur?: () => void;
  onFocus?: () => void;
}

const BasicAuthInput: React.FC<IAuthInputProps> = ({
                                                     fieldType,
                                                     fieldError,
                                                     value,
                                                     onChange,
                                                     placeholder,
                                                     type,
                                                     errorText,
                                                     isVisible = true,
                                                     handleSetVisible,
                                                     onFocus,
                                                     onBlur
                                                   }) => {
  return (
      <label className={styled.auth_input_label} data-field={fieldType} data-in-error={fieldError}>
          <input
              className={styled.auth_input}
              value={value}
              onChange={onChange}
              placeholder={placeholder}
              type={type}
              onFocus={onFocus}
              onBlur={onBlur}
          />
          <span className={styled.auth_input_error_text} data-in-error={fieldError}>{errorText}</span>
          {fieldType === 'password' && handleSetVisible &&
              <HiddenEyeButton isHidden={isVisible} handle={handleSetVisible} title={'Show password'}/>}
      </label>
  );
};

export default BasicAuthInput;