import React, {useState} from 'react';
import {ORDERS_TYPES} from '../../../Pages/CreateCampaignPage/Constants/OrdersTypes';
import styled from './styled.module.scss';

interface IBasicSelectProps {
  selectedType: string | null;
  handleSelectItem: (newType: string | null, prevType: string | null) => void;
  placeholder: string;
  disabled?: boolean;
}

const TypeSelector: React.FC<IBasicSelectProps> = ({
                                                     selectedType,
                                                     handleSelectItem,
                                                     placeholder,
                                                     disabled
                                                   }) => {
  const [listIsOpen, setListIsOpen] = useState(false);
  const [listIsFocused, setListIsFocused] = useState(false);

  const handleOpenList = () => {
    setListIsOpen(true);
  };

  const handleCloseList = () => {
    setListIsOpen(false);
  };

  const getInputValue = (): string => {
    return selectedType ? ORDERS_TYPES.find(item => selectedType.includes(item.id))?.title || '' : '';
  };

  return (
    <div data-list-is-open={listIsOpen} className={styled.select_block}>
      <input
          className={styled.input_field}
        disabled={disabled}
        type={'text'}
        placeholder={placeholder}
        readOnly
        onMouseDown={() => {
          setListIsFocused(!listIsFocused);
          setListIsOpen(!listIsOpen);
        }}
        onFocus={() => {
          setListIsFocused(true);
          handleOpenList();
        }}
        onBlur={() => {
          handleCloseList();
          setListIsFocused(false);
        }}
        value={getInputValue()}
        data-is-open={listIsOpen}
      />
      <div className={styled.option_block} data-is-open={listIsOpen} data-is-focused={listIsFocused}>
        <ul className={styled.options_list}>
          {ORDERS_TYPES && ORDERS_TYPES.map(item =>
            <li className={styled.option_item} key={item.id}
                        onMouseDown={() => handleSelectItem(item.id, selectedType)}>{item.title}</li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default TypeSelector;