import React, {useState} from 'react';
import {
  AuthFormContainer, AuthFormErrorText,
  AuthSelector,
  AuthSelectorButton
} from './styled';
import {IUserData} from '../../AuthPage';
import {
  ERROR_INCORRECT_EMAIL_OR_PASSWORD,
  ERROR_USER_ALREADY_EXIST
} from '../../Constants/AuthErrorsList';
import AuthForm from '../Forms/AuthForm';
import {useNavigate} from 'react-router-dom';
import ClosedRegForm from '../Forms/ClosedRegForm';
import styled from './style.module.scss';

interface IAuthFormProps {
  handleSubmit: (userData: IUserData) => void;
  validationErrors: string[];
  handleClearErrorsList: () => void;
  authMode: 'singIn' | 'singUp';
  refKey: string | undefined;
}


const AuthWindow = ({handleSubmit, validationErrors, handleClearErrorsList, authMode, refKey}: IAuthFormProps) => {
  const [isVisible, setIsVisible] = useState(false);

  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [retryPassword, setRetryPassword] = useState('');

  const handleSetVisible = () => {
    setIsVisible(!isVisible);
  };

  const handleSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmit({username, password, retryPassword, authMode});
  };

  const handleChangeMode = (mode: 'singIn' | 'singUp') => {
    if (mode === 'singIn') {
      navigate('/authorization');
    }

    if (mode === 'singUp') {
      navigate('/registration');
    }

    handleClearErrorsList();
    setUsername('');
    setPassword('');
    setRetryPassword('');
  };

  // const handleSetRetryPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const allowedCharacters = /[a-zA-Z0-9!@#$%~^&*()_+\-=[\]{};':"\\|,.<>/?]/g;
  //   const inputValue = event.target.value;
  //   const filteredValue = inputValue.match(allowedCharacters)?.join('');
  //
  //   if (filteredValue !== inputValue) {
  //     event.target.value = filteredValue || '';
  //   }
  //
  //   setRetryPassword(event.target.value);
  //   handleClearErrorsList();
  // };

  const handleSetPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const allowedCharacters = /[a-zA-Z0-9!@#$%~^&*()_+\-=[\]{};':"\\|,.<>/?]/g;
    const inputValue = event.target.value;
    const filteredValue = inputValue.match(allowedCharacters)?.join('');

    if (filteredValue !== inputValue) {
      event.target.value = filteredValue || '';
    }

    setPassword(event.target.value);
    handleClearErrorsList();
  };

  const handleSetEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
    handleClearErrorsList();
  };


  return (
    <AuthFormContainer>
      <AuthSelector>
        <AuthSelectorButton
          onClick={() => handleChangeMode('singIn')}
          disabled={authMode === 'singIn'}
        >Sign In</AuthSelectorButton>
        <AuthSelectorButton
          onClick={() => handleChangeMode('singUp')}
          disabled={authMode === 'singUp'}
        >Sign Up</AuthSelectorButton>
      </AuthSelector>
      {/*<StyledForm>*/}
      {authMode === 'singIn' &&
        <AuthForm
          username={username}
          password={password}
          validationErrors={validationErrors}
          handleSetEmail={handleSetEmail}
          handleSetPassword={handleSetPassword}
          handleSetVisible={handleSetVisible}
          isVisible={isVisible}
          handleSubmitForm={handleSubmitForm}
          refKey={refKey}
        />
      }
      {authMode === 'singUp' &&
        <ClosedRegForm/>
        // <RegForm
        //   retryPassword={retryPassword}
        //   handleSetRetryPassword={handleSetRetryPassword}
        //   username={username}
        //   password={password}
        //   validationErrors={validationErrors}
        //   handleSetEmail={handleSetEmail}
        //   handleSetPassword={handleSetPassword}
        //   handleSetVisible={handleSetVisible}
        //   isVisible={isVisible}
        // />
      }
      {/*<AuthSubmitBlock>*/}
      {/*<BasicButton*/}
      {/*  onClick={handleSubmitForm}*/}
      {/*  size={'lg'}*/}
      {/*  text={authMode === 'singIn' ? 'Sign In' : 'Sign Up'}*/}
      {/*  variant={'blue'}*/}
      {/*/>*/}
      {/*{authMode === 'singUp' &&*/}
      {/*  <AuthSubmitPrivacy>*/}
      {/*    By registering on the site, you agree to our <br/>*/}
      {/*    <a href={'/registration/privacy_policy'} target={'_blank'}>Privacy policy</a> and <a*/}
      {/*    href={'/registration/terms_of_use'} target={'_blank'}>Terms of use</a> of*/}
      {/*    the site.*/}
      {/*  </AuthSubmitPrivacy>*/}
      {/*}*/}
      {/*</AuthSubmitBlock>*/}
      {/*<AuthButtonWrapper>*/}
      {/*  <TelegramAuthButton refKey={refKey}/>*/}
      {/*  <GoogleAuthButton refKey={refKey}/>*/}
      {/*</AuthButtonWrapper>*/}
      {/*</StyledForm>*/}
      <AuthFormErrorText
        data-in-error={validationErrors.includes(ERROR_INCORRECT_EMAIL_OR_PASSWORD)}>{ERROR_INCORRECT_EMAIL_OR_PASSWORD}</AuthFormErrorText>
      <AuthFormErrorText
        data-in-error={validationErrors.includes(ERROR_USER_ALREADY_EXIST)}>{ERROR_USER_ALREADY_EXIST}</AuthFormErrorText>
    </AuthFormContainer>
  );
};

export default AuthWindow;